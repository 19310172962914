import { Injectable } from '@angular/core';
import { ToastController } from '@gyselroth/ionic-angular';
import { ToastOptions } from '@gyselroth/ionic-core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private defaultToastoptions: ToastOptions = {
    position: 'top',
    duration: 3000,
  };

  private stackedToasts: HTMLIonToastElement[] = [];

  constructor(private _toastCtrl: ToastController) {}

  private DEFAULT_MSGS_TEXT = {
    error: 'Ein Fehler ist aufgetreten',
  };

  public DEFAULT_MSGS = {
    error: (toastOptions?: ToastOptions) =>
      this.presentToast({
        message: this.DEFAULT_MSGS_TEXT.error,
        ...toastOptions,
      }),
  };

  public DEFAULT_MSGS_STACKED = {
    error: (toastOptions?: ToastOptions) =>
      this.presentPushNotificationToast({
        message: this.DEFAULT_MSGS_TEXT.error,
        ...toastOptions,
      }),
  };

  private async createToast(
    toastOptions: ToastOptions
  ): Promise<HTMLIonToastElement> {
    if (toastOptions.duration === 0) {
      this.dismissAllToasts();
    }

    const toast = await this._toastCtrl.create({
      ...this.defaultToastoptions,
      ...toastOptions,
    });

    return toast;
  }

  public async presentToast(
    toastOptions: ToastOptions
  ): Promise<HTMLIonToastElement> {
    const lastToast = await this._toastCtrl.getTop();
    const toast = await this.createToast(toastOptions);

    if (lastToast) {
      if (this.stackedToasts.length > 0) {
        if (toast.position === 'top') {
          await this.stackToastTop(toast, lastToast);
        } else {
          await this.stackToast(toast, lastToast);
        }
      } else {
        await lastToast.dismiss();
        await toast.present();
      }
    } else {
      await toast.present();
    }

    return toast;
  }

  public async presentPushNotificationToast(
    toastOptions: ToastOptions
  ): Promise<HTMLIonToastElement> {
    const lastToast = await this._toastCtrl.getTop();
    const toast = await this.createToast(toastOptions);

    this.stackedToasts.push(toast);

    if (lastToast) {
      if (toast.position === 'top') {
        await this.stackToastTop(toast, lastToast);
      } else {
        await this.stackToast(toast, lastToast);
      }
    } else {
      await toast.present();
    }

    return toast;
  }

  private async stackToast(
    toast: HTMLIonToastElement,
    lastToast: HTMLIonToastElement
  ): Promise<void> {
    const toastWrapper = toast.shadowRoot?.querySelector(
      '.toast-wrapper.toast-bottom'
    ) as HTMLDivElement;

    toast.onWillDismiss().then(() => {
      this.stackedToasts.shift();
      toastWrapper.style.bottom = '0px';
    });

    toast.duration += this.stackedToasts.length * 500;
    const lastToastWrapper = lastToast.shadowRoot?.querySelector(
      '.toast-wrapper.toast-bottom'
    ) as HTMLDivElement;
    const lastToastWrapperHeight = Number(
      getComputedStyle(lastToastWrapper).height.split('px')[0]
    );
    toastWrapper.style.bottom =
      Number(lastToastWrapper.style.bottom.split('px')[0]) +
      Number(lastToastWrapperHeight) +
      5 +
      'px';
    toastWrapper.style.transition = 'bottom 400ms';
    await toast.present();
  }

  private async stackToastTop(
    toast: HTMLIonToastElement,
    lastToast: HTMLIonToastElement
  ): Promise<void> {
    const toastWrapper = toast.shadowRoot?.querySelector(
      '.toast-wrapper.toast-top'
    ) as HTMLDivElement;

    toast.onWillDismiss().then(() => {
      this.stackedToasts.shift();
      toastWrapper.style.top = '0px';
    });

    toast.duration += this.stackedToasts.length * 500;
    const lastToastWrapper = lastToast.shadowRoot?.querySelector(
      '.toast-wrapper.toast-top'
    ) as HTMLDivElement;
    const lastToastWrapperHeight = Number(
      getComputedStyle(lastToastWrapper).height.split('px')[0]
    );
    toastWrapper.style.top =
      Number(lastToastWrapper.style.top.split('px')[0]) +
      Number(lastToastWrapperHeight) +
      5 +
      'px';
    toastWrapper.style.transition = 'top 400ms';
    await toast.present();
  }

  public async dismissLastToast(): Promise<void> {
    const lastToast = await this._toastCtrl.getTop();
    if (lastToast) {
      await lastToast.dismiss();
    }
  }

  public async dismissAllToasts(): Promise<void> {
    const allToasts = document.querySelectorAll('ion-toast');
    allToasts.forEach(async toast => {
      await toast.dismiss();
    });
  }
}
