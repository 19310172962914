import { Organization, OrganizationGQL } from 'src/app/generated/graphql';
import { Injectable, inject, OnDestroy } from '@angular/core';

import { ToastService } from '../../helper/services/toast.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { OrganizationConfigService } from './organizationConfig.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService implements OnDestroy {
  private _organizationGQL = inject(OrganizationGQL);
  private _toastService = inject(ToastService);
  private _organizationConfigService = inject(OrganizationConfigService);

  private _subscription: Subscription[] = [];

  public clientConfig$: ReplaySubject<Organization> = new ReplaySubject(1);

  ngOnDestroy() {
    this._subscription.forEach(item => item.unsubscribe());
  }

  public getOrganizationFromGQL(): void {
    const subscription = this._organizationGQL
      .watch({
        filter: {
          shortName: this._organizationConfigService.organization.shortName,
        },
      })
      .valueChanges.subscribe(({ data, errors }) => {
        if (errors) {
          this._toastService.DEFAULT_MSGS_STACKED.error();
          return;
        }

        if (data.organization.__typename !== 'Organization') {
          this._toastService.DEFAULT_MSGS_STACKED.error({
            message: data.organization.message,
          });
          return;
        }

        this.clientConfig$.next(data.organization as Organization);
      });

    this._subscription.push(subscription);
  }
}
