export const possibleTypes = {
    "NavigationItemResult": [
        "NavigationItem",
        "UnauthorizedError",
        "NotFoundError"
    ],
    "Error": [
        "UnauthorizedError",
        "NotFoundError",
        "ServiceUnavailableError",
        "ForbiddenError"
    ],
    "TileResult": [
        "Tile",
        "NotFoundError"
    ],
    "OrganizationResult": [
        "Organization",
        "NotFoundError"
    ],
    "TagResult": [
        "Tag",
        "UnauthorizedError",
        "NotFoundError"
    ],
    "MailResult": [
        "Mail",
        "UnauthorizedError",
        "ServiceUnavailableError"
    ],
    "MoodleResult": [
        "Moodle",
        "UnauthorizedError",
        "ServiceUnavailableError"
    ],
    "TileCreateResult": [
        "Tile",
        "UnauthorizedError",
        "NotFoundError",
        "ForbiddenError"
    ],
    "TileUpdateResult": [
        "Tile",
        "UnauthorizedError",
        "NotFoundError",
        "ForbiddenError"
    ],
    "TileDeleteResult": [
        "Tile",
        "UnauthorizedError",
        "NotFoundError",
        "ForbiddenError"
    ],
    "TagCreateResult": [
        "Tag",
        "UnauthorizedError",
        "NotFoundError",
        "ForbiddenError"
    ],
    "TagUpdateResult": [
        "Tag",
        "UnauthorizedError",
        "NotFoundError",
        "ForbiddenError"
    ],
    "TagDeleteResult": [
        "Tag",
        "UnauthorizedError",
        "NotFoundError",
        "ForbiddenError"
    ],
    "LinkCreateResult": [
        "Link",
        "UnauthorizedError",
        "NotFoundError"
    ],
    "LinkUpdateResult": [
        "Link",
        "UnauthorizedError",
        "NotFoundError",
        "ForbiddenError"
    ],
    "LinkDeleteResult": [
        "Link",
        "UnauthorizedError",
        "NotFoundError",
        "ForbiddenError"
    ],
    "TileUsageIncrementResult": [
        "TileUsage",
        "UnauthorizedError",
        "NotFoundError"
    ]
}