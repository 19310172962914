import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { take } from 'rxjs/operators';

import { AuthService } from '@gyselroth/angular-auth';
import { OrganizationService } from './shared/organization/services/organization.service';
import { OrganizationConfigService } from './shared/organization/services/organizationConfig.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private organizationService: OrganizationService,
    private organizationConfigService: OrganizationConfigService
  ) {
    this.organizationConfigService.initiateOrganizationFromConfig();

    this.authService.loggedIn$.pipe(take(1)).subscribe(_ => {
      this.hideLoader();
    });

    this.organizationService.getOrganizationFromGQL();
  }

  private hideLoader(): void {
    const loaderFadeOutTimeout = 300;
    const body = this.document.getElementsByTagName('body')[0];
    const loader = this.document.getElementById('login-loader');

    if (loader !== null) {
      loader.style.setProperty(
        '--login-loader-fade-out-timeout',
        `${loaderFadeOutTimeout}ms`
      );
      loader.classList.add('ready-for-take-off');
      if (body) body.classList.remove('no-scroll');
      setTimeout(() => loader.remove(), loaderFadeOutTimeout + 10);
    }
  }
}
