export interface OrganizationConfig {
  shortName: string;
  urls: string[];
  loggedInRedirectToUrl: string;
  mailEnding: string[];
  //will be removed after https://gitlab.gyselroth.net/move2one/ui-web/-/issues/86
  moodleRedirectLink?: string;
}

export const organizationConfigs: OrganizationConfig[] = [
  {
    shortName: 'gr',
    urls: ['http://gr.localhost:4200', 'https://gr.move2one.gyselroth.net'],
    loggedInRedirectToUrl: 'mails',
    mailEnding: ['@gyselroth.com'],
  },
  {
    shortName: 'kst',
    urls: [
      'http://kst.localhost:4200',
      'https://kst.move2one.gyselroth.net',
      'https://move2one.gyselroth.net',
      'https://movetoone.ksstadelhofen.ch',
    ],
    loggedInRedirectToUrl: 'news',
    mailEnding: ['@ksstadelhofen.ch'],
    moodleRedirectLink:
      'https://moodle.ksstadelhofen.ch/auth/shibboleth/index.php',
  },
];
