import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
import moment from 'moment-timezone';

/*
 * Format a moment.MomentInput with moment.format():
 * Takes a format argument that defaults to "L"
 * If an integer is suppliead as value, it mus be a valid unix timestamp
 * Usage: value | moment: format : config?
 * Example: {{ date | moment:'LLL' }}
 */
class MomentPipe {
  transform(value, format, config) {
    const input = typeof value === 'number' ? value * 1000 : value;
    const m = moment(input);
    if (config?.timeZone) {
      m.tz(config.timeZone);
    }
    if (config?.locale) {
      m.locale(config.locale);
    }
    return m.format(format || 'L');
  }
}
MomentPipe.ɵfac = function MomentPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MomentPipe)();
};
MomentPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "moment",
  type: MomentPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MomentPipe, [{
    type: Pipe,
    args: [{
      name: 'moment'
    }]
  }], null, null);
})();

// tslint:disable-next-line:only-arrow-functions
function processRelativeTime(n, withoutSuffix, key, _) {
  const formats = {
    s: 'ein paar Sekunden',
    ss: n + ' Sekunden',
    m: ['eine Minute', 'einer Minute'],
    mm: n + ' Minuten',
    d: ['ein Tag', 'einem Tag'],
    dd: [n + ' Tage', n + ' Tagen'],
    h: ['eine Stunde', 'einer Stunde'],
    hh: n + ' Stunden',
    w: ['eine Woche', 'einer Woche'],
    ww: n + ' Wochen',
    M: ['ein Monat', 'einem Monat'],
    MM: [n + ' Monate', n + ' Monaten'],
    y: ['ein Jahr', 'einem Jahr'],
    yy: [n + ' Jahre', n + ' Jahren']
  };
  const format = formats[key];
  if (Array.isArray(format)) {
    return !withoutSuffix && format[1] ? format[1] : format[0];
  }
  return format;
}
const defaultLocaleSpecification = {
  months: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
  monthsShort: 'Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sep._Okt._Nov._Dez.'.split('_'),
  monthsParseExact: true,
  weekdays: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
  weekdaysShort: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
  weekdaysMin: 'S_M_D_M_D_F_S'.split('_'),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'ddd. DD.MM.YYYY',
    LLL: 'DD.MM.YYYY, HH:mm',
    LLLL: 'dddd, D. MMMM YYYY HH:mm'
  },
  calendar: {
    sameDay: '[heute um] LT [Uhr]',
    sameElse: 'L',
    nextDay: '[morgen um] LT [Uhr]',
    nextWeek: 'dddd [um] LT [Uhr]',
    lastDay: '[gestern um] LT [Uhr]',
    lastWeek: '[letzten] dddd [um] LT [Uhr]'
  },
  relativeTime: {
    future: 'in %s',
    past: 'vor %s',
    s: processRelativeTime,
    ss: processRelativeTime,
    m: processRelativeTime,
    mm: processRelativeTime,
    h: processRelativeTime,
    hh: processRelativeTime,
    d: processRelativeTime,
    dd: processRelativeTime,
    w: processRelativeTime,
    ww: processRelativeTime,
    M: processRelativeTime,
    MM: processRelativeTime,
    y: processRelativeTime,
    yy: processRelativeTime
  },
  dayOfMonthOrdinalParse: /\d{1,2}\./,
  week: {
    dow: 1,
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  }
};
function configureMoment(timeZone = 'Europe/Zurich', locale = 'de-ch', localeSpecification) {
  moment.defineLocale(locale, localeSpecification || defaultLocaleSpecification);
  moment.locale(locale);
  moment.tz.setDefault(timeZone);
}
class MomentModule {}
MomentModule.ɵfac = function MomentModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MomentModule)();
};
MomentModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MomentModule
});
MomentModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MomentModule, [{
    type: NgModule,
    args: [{
      declarations: [MomentPipe],
      exports: [MomentPipe],
      providers: []
    }]
  }], null, null);
})();

/*
 * Public API Surface of angular-moment
 */

/**
 * Generated bundle index. Do not edit.
 */

export { MomentModule, MomentPipe, configureMoment };
