import {
  AbstractAuthConfigService,
  IAuthConfig,
  OidcProvider,
} from '@gyselroth/angular-auth';
import { inject, Injectable } from '@angular/core';
import { delay, map, Observable, of, take, tap } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { OrganizationConfigService } from '../../organization/services/organizationConfig.service';

@Injectable({
  providedIn: 'root',
})
export class AuthConfigService extends AbstractAuthConfigService {
  private _organizationConfigService = inject(OrganizationConfigService);

  protected _config: IAuthConfig = {
    loggedOutRedirectToUrl: '/login',
    loggedInRedirectToUrl: async () => {
      return `/dashboard/${this._organizationConfigService.organization.loggedInRedirectToUrl}`;
    },
    isAuthorizedUser: async (_, __, user) => {
      let result = false;
      if (user && user.organization) {
        result =
          this._organizationConfigService.organization.shortName ===
          user.organization;
      }
      return result;
    },
  };

  protected _oidcConfig?: OidcProvider;

  public loadConfig(): Observable<boolean> {
    return this.loadRemoteConfig().pipe(
      take(1),
      tap(cfg => (this._oidcConfig = cfg)),
      map(() => true)
    );
  }

  private loadRemoteConfig(): Observable<OidcProvider> {
    const authConfig: OidcProvider = {
      issuer: environment.oidcConfig.issuer,
      clientId: environment.oidcConfig.clientId,
      scope: 'openid profile email intranet offline_access',
      // for further configuration options see `AuthConfig`
      // in `angular-oauth2-oidc` package
      redirectUri: location.origin + '/login',
    };

    return of(authConfig).pipe(delay(1e3));

    //TODO organization specific providers
    /*return from(this._providerService.loadOrganization()).pipe(
      switchMap((organization: Organization) => {
        const authConfig: OidcProvider = {
          issuer: environment.oidcConfig.issuer,
          clientId: environment.oidcConfig.clientId,
          scope: 'openid profile email intranet offline_access',
          // for further configuration options see `AuthConfig`
          // in `angular-oauth2-oidc` package
          redirectUri: location.origin + '/login',
        };

        return of(authConfig).pipe(delay(1e3));
      })
    );*/
  }
}
