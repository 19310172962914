import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class HelperService {
  private _defaultTileImageBase64 = `data:image/svg+xml;base64,PHN2ZyBpZD0iSWNvbnMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2
                                    ZyIgdmlld0JveD0iMCAwIDI0IDI0Ij48cmVjdCB4PSIxMyIgeT0iMTEiIHdpZHRo
                                    PSI2IiBoZWlnaHQ9IjIiIHN0eWxlPSJmaWxsOiM5OTkiLz48cGF0aCBkPSJNMTMs
                                    OWgyVjdIMTNhNSw1LDAsMCwwLDAsMTBoMlYxNUgxM2EzLDMsMCwwLDEsMC02WiIg
                                    c3R5bGU9ImZpbGw6Izk5OSIvPjxwYXRoIGQ9Ik04LjExLDdIMFY5SDYuNjlBNi43
                                    NCw2Ljc0LDAsMCwxLDguMTEsN1oiIHN0eWxlPSJmaWxsOiM5OTkiLz48cGF0aCBk
                                    PSJNMTksN0gxN1Y5aDJhMywzLDAsMCwxLDAsNkgxN3YyaDJBNSw1LDAsMCwwLDE5
                                    LDdaIiBzdHlsZT0iZmlsbDojOTk5Ii8+PHBhdGggZD0iTTYsMTJhNi42Myw2LjYz
                                    LDAsMCwxLC4wOC0xSDJ2Mkg2LjA4QTYuNjMsNi42MywwLDAsMSw2LDEyWiIgc3R5
                                    bGU9ImZpbGw6Izk5OSIvPjwvc3ZnPg==`;

  constructor(private sanitizer: DomSanitizer) {}

  public renderDefaultImage(): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(this._defaultTileImageBase64);
  }

  public stripHtmlToText(html: string): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;

    let res = tmp.textContent || tmp.innerText || '';
    res.replace('\u200B', '');

    return res.trim();
  }
}
