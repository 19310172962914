import { Injectable } from '@angular/core';

import {
  OrganizationConfig,
  organizationConfigs,
} from '../organization.config';

@Injectable({
  providedIn: 'root',
})
export class OrganizationConfigService {
  public organization!: OrganizationConfig;

  public getOrganization(): OrganizationConfig | null {
    return (
      organizationConfigs.find(organization =>
        organization.urls.includes(window.location.origin)
      ) ?? null
    );
  }

  public initiateOrganizationFromConfig(): void {
    for (const organization of organizationConfigs) {
      if (organization.urls.includes(window.location.origin)) {
        this.organization = organization;
        this.setOrganization(organization.shortName);
        break;
      }
    }
  }

  private setOrganization(shortName: string): void {
    if (shortName !== '') {
      this.organizationFavicon(shortName);
      this.organizationTheme(shortName);
      this.organizationFont(shortName);
    }
  }

  private organizationTheme(organizationName: string): void {
    const htmlHeader = document.getElementById('index-root');
    htmlHeader?.classList.add(`gr-theme-${organizationName}`);
  }

  private organizationFavicon(organizationName: string): void {
    const appleTouchIcon = document.getElementById(
      'apple-touch-icon'
    ) as HTMLLinkElement;
    appleTouchIcon.href = `/assets/${organizationName}_favicons/apple-touch-icon.png`;

    const favIcon32 = document.getElementById('favicon_32') as HTMLLinkElement;
    favIcon32.href = `/assets/${organizationName}_favicons/favicon-32x32.png`;

    const favIcon16 = document.getElementById('favicon_16') as HTMLLinkElement;
    favIcon16.href = `/assets/${organizationName}_favicons/favicon-16x16.png`;

    const manifest = document.getElementById('manifest') as HTMLLinkElement;
    manifest.href = `assets/${organizationName}_favicons/site.webmanifest`;

    const maskIcon = document.getElementById('mask-icon') as HTMLLinkElement;
    maskIcon.href = `/assets/${organizationName}_favicons/safari-pinned-tab.svg`;
  }

  private organizationFont(organizationName: string): void {
    const htmlHeader = document.getElementById('m2o-head');

    const font = document.createElement('link');
    font.type = 'text/css';
    font.rel = 'stylesheet';

    switch (organizationName) {
      case 'kst':
      case 'gr':
        font.href =
          '//fast.fonts.net/cssapi/a6ef0494-84a8-4635-9fd3-4ecd3da23d04.css';
    }

    htmlHeader?.appendChild(font);
  }
}
