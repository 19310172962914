import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { configureMoment } from '@gyselroth/angular-moment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { GriconsAngularModule } from '@gyselroth/gricons-angular';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule } from '@gyselroth/ionic-angular';
import moment from 'moment-timezone';
import { AbstractAuthConfigService, AuthModule } from '@gyselroth/angular-auth';
import { AuthConfigService } from './shared/auth-config/services/auth-config.service';
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalSkipInterceptor } from './shared/msal/services/msalskip-interceptor.service';
import { environment } from 'src/environments/environment';
const timeZone = 'Europe/Zurich';
configureMoment(timeZone);

export function configInitializer(configurationService: AuthConfigService) {
  return () => configurationService.loadConfig();
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: [
        // 'user.read User.ReadBasic.All ChannelMessage.Read.All Channel.ReadBasic.All Team.ReadBasic.All openid profile offline_access api://d0349e9f-7609-43f3-beee-6cf2ac3ccccb/move2one',
        'user.read User.ReadBasic.All ChannelMessage.Read.All Channel.ReadBasic.All Team.ReadBasic.All openid profile offline_access api://b022df5b-ae76-4fc2-84a2-f38ea367866e/move2one',
      ],
    },
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.msalgraph, [
    // 'user.read User.ReadBasic.All ChannelMessage.Read.All Channel.ReadBasic.All Team.ReadBasic.All openid profile offline_access api://d0349e9f-7609-43f3-beee-6cf2ac3ccccb/move2one',
    'user.read User.ReadBasic.All ChannelMessage.Read.All Channel.ReadBasic.All Team.ReadBasic.All openid profile offline_access api://b022df5b-ae76-4fc2-84a2-f38ea367866e/move2one',
  ]);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    RouterModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    GriconsAngularModule.forRoot(),
    GraphQLModule,
    CommonModule,
    IonicModule.forRoot({
      moment,
      timeZone,
      mode: 'ios',
    }),
    AuthModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        // GS Tenant
        // auth: {
        //   clientId: 'd0349e9f-7609-43f3-beee-6cf2ac3ccccb',
        //   authority:
        //     'https://login.microsoftonline.com/9f291f4e-f59f-40b7-b314-fa3fec2db038',
        //   redirectUri: location.origin + '/login',
        // },
        // KST Tenant
        auth: {
          clientId: 'b022df5b-ae76-4fc2-84a2-f38ea367866e',
          authority:
            'https://login.microsoftonline.com/10bbd5a6-abe1-4b7c-bb6f-2d78fa5f0d27',
          redirectUri: location.origin + '/login',
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
      }),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory()
    ),
  ],
  providers: [
    { provide: AbstractAuthConfigService, useExisting: AuthConfigService },
    {
      provide: APP_INITIALIZER,
      useFactory: configInitializer,
      multi: true,
      deps: [AuthConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalSkipInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
